<template>
  <div class="app">
    <el-dialog
      title="查看设备信息"
      :visible.sync="dialogSendVisible"
      width="30%"
      class="theme1"
      :before-close="handleSendClose"
    >
      <el-timeline>
        <el-row>
          <el-col :span="24">
            <span class="grid-divider">基本信息</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4"> 设备类型： </el-col>
          <el-col :span="8">
            {{ infoDetail.deviceType }}
            {{ infoDetail.typeNo }}
          </el-col>
          <el-col :span="4"> 设备型号： </el-col>
          <el-col :span="8"> - </el-col>
        </el-row>
        <el-row>
          <el-col :span="4"> S/N码： </el-col>
          <el-col :span="8">
            {{ infoDetail.snCode }}
          </el-col>
          <el-col :span="4"> 设备名称： </el-col>
          <el-col :span="8">
            {{ infoDetail.deviceName }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4"> 所属商家： </el-col>
          <el-col :span="8">
            {{ infoDetail.merchantName }}
          </el-col>
          <el-col :span="4"> 所属门店： </el-col>
          <el-col :span="8">
            {{ infoDetail.shopName }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4"> 激活时间： </el-col>
          <el-col :span="8"> - </el-col>
          <el-col :span="4"> 收款码ID： </el-col>
          <el-col :span="8">
            {{ infoDetail.payCode }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4"> 所属BD： </el-col>
          <el-col :span="8">
            {{ infoDetail.bdName }}
          </el-col>
          <el-col :span="24">
            <el-divider></el-divider>
            <span class="grid-divider">押金信息</span>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4"> 设备押金： </el-col>
          <el-col :span="8">
            {{ infoDetail.cashPledge }}
          </el-col>
          <el-col :span="4"> 缴纳时间： </el-col>
          <el-col :span="8"> - </el-col>
        </el-row>

        <el-row>
          <el-col :span="4"> 退款条件： </el-col>
          <el-col :span="8"> </el-col>
          <el-col :span="4"> 活动状态： </el-col>
          <el-col :span="8">
            <!--                        <el-link type="success" disabled>已完成</el-link>-->
            <!--                        <el-link type="danger" disabled>未完成</el-link>-->
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4"> 退款状态： </el-col>
          <el-col :span="8">
            <!--                        <el-link type="primary" disabled>原路退回</el-link>/<el-link type="primary" disabled>退回余额</el-link>-->
          </el-col>
          <el-col :span="4"> </el-col>
          <el-col :span="8"> </el-col>
        </el-row>

        <el-row>
          <el-col :span="4"> 失败原因： </el-col>
          <el-col :span="8"> - </el-col>
        </el-row>
      </el-timeline>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    row: Object,
  },
  data() {
    return {
      dialogSendVisible: false,
      infoDetail: "",
    };
  },
  methods: {
    handleSendClose() {
      this.dialogSendVisible = false;
      this.$emit("dialog", false);
    },
    showDetail() {
      this.$post(this.$api.deviceDetail + "?id=" + this.row.id, {}).then(
        (res) => {
          if (res.code == 1) {
            this.infoDetail = res.data;
          }
        }
      );
    },
  },
  created() {
    this.dialogSendVisible = true;
    this.showDetail();
  },
};
</script>
<style lang="scss" scoped>
.theme1 {
  .grid-divider {
    display: block;
    margin-bottom: 10px;
    font-size: large;
    color: #000000;
  }
  .el-col-4,
  .el-col-8 {
    height: 35px;
    line-height: 35px;
  }
  .el-col-4 {
    color: #000000;
  }
  .el-col-8 {
    color: rgba(0, 0, 0, 0.647);
  }
  .el-dialog__header {
    border-bottom: #DCDFE6 1px double !important;
  }
}
</style>
