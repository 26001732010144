<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>设备管理</el-breadcrumb-item>
          <el-breadcrumb-item>设备列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="header-form">
        <div class="admin_main_block_left">
          <el-form>
            <el-form-item label="设备类型">
              <el-select
                v-model="where.deviceType"
                placeholder="请选择"
                clearable
                @change="getDeviceTypeNo"
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  v-for="(item, i) in deviceTypeList"
                  :label="item.deviceName"
                  :value="item.deviceType"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备厂商">
              <el-select
                v-model="where.deviceManufacturer"
                placeholder="请选择"
                clearable
                @change="getDeviceTypeNo"
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  v-for="(item, i) in deviceManufacturerList"
                  :label="item.manufacturerName"
                  :value="item.manufacturerCode"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备型号">
              <el-select
                v-model="where.deviceName"
                placeholder="请选择"
                clearable
                @change="setTypeNo"
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  v-for="(item, i) in deviceTypeNoList"
                  :label="item.typeName"
                  :value="item"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="绑定状态">
              <el-select v-model="where.state" placeholder="请选择" clearable>
                <el-option label="全部" value=""></el-option>
                <el-option label="已绑定" value="1"></el-option>
                <el-option label="未绑定" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="播报状态">
              <el-select
                v-model="where.deviceState"
                placeholder="请选择"
                clearable
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="开启" value="1"></el-option>
                <el-option label="关闭" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="S/N码">
              <el-input
                v-model="where.snCode"
                placeholder="请输入S/N码"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="关键字">
              <el-input
                v-model="where.keyWord"
                placeholder="SN号/BD/门店"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="激活时间">
              <el-date-picker
                v-model="date"
                @change="orderDate" @input="immediUpdate"
                type="daterange"
                range-separator="~"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                                start-placeholder="起始日期"
                end-placeholder="截止日期"
              >
                <i class="el-icon-date"></i>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain icon="el-icon-search" @click="listData()"
                >条件筛选</el-button
              >
            </el-form-item>
          </el-form>
        </div>

        <div class="admin_main_block_right">
          <el-row :gutter="20">
            <el-col :span="12">
              <!--                            <el-button type="primary" @click="add" icon="el-icon-plus">新增</el-button>-->
            </el-col>
            <el-col :span="12">
              <el-button
                type="primary"
                @click="exportData"
                disabled
                icon="el-icon-tickets"
                >导出数据</el-button
              >
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="admin_table_main">
        <el-table
          :data="list"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.1)"
          @selection-change="handleSelectionChange"
        >
          <el-table-column prop="deviceType" label="序号">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="batchNo" label="批次编号"></el-table-column>
          <el-table-column prop="deviceName" label="设备">
            <template slot-scope="scope">
              {{ scope.row.deviceName }}
              {{ scope.row.typeNo }}
            </template>
          </el-table-column>
          <el-table-column prop="snCode" label="S/N码"></el-table-column>
          <el-table-column
            prop="cashPledge"
            label="押金（元）"
          ></el-table-column>
          <el-table-column prop="bdName" label="所属BD"></el-table-column>
          <el-table-column
            prop="merchantId"
            label="所属商家ID"
          ></el-table-column>
          <el-table-column prop="shopName" label="所属门店"></el-table-column>
          <el-table-column prop="createDate" label="激活时间"></el-table-column>
          <el-table-column prop="deviceType" label="活动状态">
            <template slot-scope="scope">
              <el-tag type="primary" plain v-if="scope.row.deviceState == 1">{{
                scope.row.deviceStateName
              }}</el-tag>
              <el-tag type="danger" plain v-if="scope.row.deviceState == 2">{{
                scope.row.deviceStateName
              }}</el-tag>
            </template>
          </el-table-column>
          <!--<el-table-column prop="deviceType" label="播报状态">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.state" @change="setState(scope.row)" active-color="#13ce66"
                                       inactive-color="#ff4949"></el-switch>
                        </template>
                    </el-table-column>-->
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="primary"
                class="el-icon-tickets"
                @click="showDailog(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="admin_table_main_pagination">
          <el-pagination
            @current-change="currentChange"
            @size-change="handleSizeChange"
            background
            :total="totalData"
            :page-size="pageSize"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            layout="sizes, prev, pager, next, total, jumper"
          ></el-pagination>
        </div>
      </div>
      <info :row="row" @dialog="getDialog" v-if="dialogDetailVisible"></info>
    </div>
  </div>
</template>

<script>
import info from "@/components/admin/device/info.vue";
export default {
  components: {
    info,
  },
  props: {
    state: Number,
  },
  data() {
    return {
      list: [],
      date: "",
      row: {},
      deviceTypeList: "",
      deviceManufacturerList: "",
      deviceTypeNoList: "",
      loading: false,
      dialogDetailVisible: false,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      orderStatusList: [],
      selectId: "",
      where: {
        page: 0,
        limit: 0,
        deviceType: "",
        batchNo: "",
      },
    };
  },
  methods: {
    listData() {
      this.loading = true;
      this.where.page = this.currentPage;
      this.where.limit = this.pageSize;
      this.$post(this.$api.deviceList, this.where).then((res) => {
        this.totalData = res.data.count;
        this.list = res.data.data;
        this.loading = false;
      });
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    orderDate(obj) {
      if (obj) {
        this.where.startDate = obj[0];
        this.where.endDate = obj[1];
      }
    },
    immediUpdate(e) {
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.where.startDate = '';
          this.where.endDate = '';
        }
      });
    },
    exportData() {
      // console.log("exportData");
    },
    add() {
      // this.$router.push({ name: "operationscenterAdd" });
    },
    getDialog(val) {
      this.dialogDetailVisible = val;
    },
    setState(data) {
      console.log(data, "setState");
    },
    showDailog(data) {
      this.row = data;
      this.dialogDetailVisible = true;
    },
    getDeviceType() {
      this.$get(this.$api.deviceType, {}).then((res) => {
        if (res.code == 1) {
          this.deviceTypeList = res.data;
        }
      });
    },
    getDeviceManufacturer() {
      this.$get(this.$api.deviceManufacturer, {}).then((res) => {
        if (res.code == 1) {
          this.deviceManufacturerList = res.data;
        }
      });
    },
    getDeviceTypeNo() {
      if (
        this.$isEmpty(this.where.deviceType) ||
        this.$isEmpty(this.where.deviceManufacturer)
      ) {
        return false;
      }
      const data = {
        deviceType: this.where.deviceType,
        deviceManufacturer: this.where.deviceManufacturer,
      };
      this.$post(this.$api.deviceTypeNo, data).then((res) => {
        if (res.code == 1) {
          this.deviceTypeNoList = res.data;
        }
      });
    },
    setTypeNo() {
      this.form.typeNo = this.type.typeNo;
      this.form.prefix = this.type.prefix;
    },
  },
  created() {
    this.listData();
    this.getDeviceType();
    this.getDeviceManufacturer();
    const orderBatch = this.$route.query.orderBatch;
    if (!this.$isEmpty(orderBatch)) {
      this.where.batchNo = orderBatch;
    }
  },
};
</script>
<style lang="scss" scoped>
.endDate {
  color: #c0c4cc;
}
.el-image {
  height: 100px;
  width: 100px;
}
.el-select {
  width: 120px;
}
</style>
